import React from "react";
import { mappedTemplates } from "../pages";
import { AdditionalProps } from "src/types/Content";
import {
  BasicPageBannerComponentDynamicZone,
  BasicPagePrimaryContentDynamicZone,
  BlogPostPagePrimaryContentDynamicZone,
  BookingConfirmationPagePrimaryContentDynamicZone,
  YourBookingPagePrimaryContentDynamicZone,
} from "src/graphql/generated-strapi/types";

interface PrimaryContentProps {
  primaryContent: (
    | BasicPagePrimaryContentDynamicZone
    | YourBookingPagePrimaryContentDynamicZone
    | BookingConfirmationPagePrimaryContentDynamicZone
    | BlogPostPagePrimaryContentDynamicZone
    | BasicPageBannerComponentDynamicZone
    | null
  )[];
  bslData?: any;
  pageImgQuality?: number;
  additionalProps?: AdditionalProps;
  hideSubscribeSticky?: boolean;
  isHomePage?: boolean;
  isGlentressLandingPageExp?: boolean;
}

const PrimaryContent = (props: PrimaryContentProps) => {
  const {
    primaryContent,
    bslData,
    pageImgQuality,
    additionalProps,
    hideSubscribeSticky,
    isHomePage,
    isGlentressLandingPageExp,
  } = props;
  const primaryContentComponent = () =>
    primaryContent?.length > 0
      ? primaryContent.map((contentObject, i) => {
          let { __typename, ...rest } = contentObject;
          const contentKey: string | undefined = !!Object.keys(rest).length
            ? Object.keys(rest)[Object.keys(rest).length - 1]
            : undefined;

          return contentKey && mappedTemplates[contentKey]
            ? React.createElement(mappedTemplates[contentKey], {
                content: rest,
                bslData: bslData,
                pageImgQuality: pageImgQuality,
                additionalProps: additionalProps,
                hideSubscribeSticky: hideSubscribeSticky,
                isHomePage: isHomePage,
                isGlentressLandingPageExp: isGlentressLandingPageExp,
                key: `primary-${contentKey}-${i}`,
              })
            : React.createElement(
                "h4",
                {
                  style: { textAlign: "center", color: "red" },
                  key: `primary-${contentKey}-${i}`,
                },
                `-- Template "${contentKey}" not mapped in Primary Content area --`,
              );
        })
      : null;
  return <>{primaryContentComponent()}</>;
};

export default PrimaryContent;
