import React, { PropsWithChildren, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Dialog, Skeleton, Typography } from "@mui/material";
import { FHNextImage } from "../FHNextImage";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { MemoizedVideo } from "../MemoizedVideo";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    maxWidth: 520,
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    width: 30,
    height: 30,
    borderRadius: "50%",
    right: 20,
    top: 20,
    zIndex: 2,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: 15,
      top: 15,
    },
  },
  image: {
    position: "relative",
    minHeight: 300,
  },
  content: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.down("md")]: {
      gap: 10,
      padding: theme.spacing(2),
    },
    "& h2": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        lineHeight: "30px",
      },
    },
  },
  action: {
    "& a": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 400,
      backgroundColor: "#111111",
      color: theme.palette.common.white,
      borderRadius: 5,
      padding: theme.spacing(0.8, 1.5),
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: "#111111",
      },
    },
  },
  videoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    overflow: "hidden",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    "& video": {
      width: "100%",
    },
  },
  heroVideo: {
    minWidth: "100%",
    height: "100%",
    backgroundSize: "cover",
    transition: "1s opacity",
    objectFit: "fill",
  },
}));

type GlentressPopUpProps = {
  title: string;
  discription?: JSX.Element;
  imgSrc?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  videoUrl?: string;
  autoplayVideo?: boolean;
};

export const GlentressPopUp: React.FC<GlentressPopUpProps> = ({
  imgSrc,
  title,
  discription,
  ctaLabel,
  ctaUrl,
  videoUrl,
  autoplayVideo,
}: PropsWithChildren<GlentressPopUpProps>) => {
  const classes = useStyles();
  const video = useRef<HTMLVideoElement>(null);
  const [cookies, setCookie] = useCookies(["hideGlentressPopUp"]);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const handleCookie = () => {
    setIsDialogOpen(false);
    !cookies?.hideGlentressPopUp && setCookie("hideGlentressPopUp", true);
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCookie}
        classes={{
          paper: classes.paperRoot,
        }}
      >
        <div className={classes.image}>
          <div onClick={handleCookie} className={classes.closeButton}>
            <CloseIcon />
          </div>
          {!videoUrl && (
            <FHNextImage
              src={imgSrc}
              alt=""
              isStaticImg
              layout="fill"
              objectFit="cover"
            />
          )}
          {videoUrl && (
            <div className={classes.videoContainer}>
              <MemoizedVideo
                {...{
                  src: videoUrl + "#t=0.001",
                  className: classNames(classes.heroVideo),
                  innerRef: video,
                  autoPlay: autoplayVideo,
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.content}>
          <Typography variant="h2">{title}</Typography>
          <Typography>{discription}</Typography>
          <div className={classes.action}>
            <Button href={ctaUrl} onClick={handleCookie}>
              {ctaLabel}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
